<template>
  <div>
    <!-- 建行云贷 -->
    <construction-bank :dictType="dictType" v-if="dictType === '1'" />
    <!-- 财富资金 -->
    <wealth-fund :dictType="dictType" v-if="dictType === '3'" />
    <!-- 中小担 -->
    <small-business :dictType="dictType" v-if="dictType === '4'" />
  </div>
</template>

<script>
import { resolutionAPi } from '@/api/businessApi'
import { getDictLists } from '@/filters/fromDict'
import ConstructionBank from './product/construction-bank.vue'
import WealthFund from './product/wealth-fund.vue'
import SmallBusiness from './product/small-business.vue'
import Storage from '@/utils/storage'
export default {
  components: { ConstructionBank, WealthFund, SmallBusiness },
  props: {},
  data () {
    return {
      product: [
        { dictTypeName: '建行云贷', dictType: '1' },
        { dictTypeName: '深国际', dictType: '2' },
        { dictTypeName: '财富资金', dictType: '3' },
        { dictTypeName: '中小企', dictType: '4' }
      ],
      products: [],
      dictType: '0' // 产品类型 1 建行云贷 2 深国际  3 财富资金 4 中小担
    }
  },
  // 计算属性 类似于data概念
  computed: {
    getDictLists () {
      return getDictLists
    },
    api () {
      return resolutionAPi
    },
    tags () {
      return JSON.parse(sessionStorage.getItem('routerList'))
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取资金方类型
    getBatch () {
      const capitalSideProductId =
        Storage.getLocal('contractInfo').capitalSideProductId
      console.log(Storage.getLocal('contractInfo').capitalSideProductId, "Storage.getLocal('contractInfo').capitalSideProductId")
      const params = { typeCodes: 'CAPITAL_PRODUCT_TYPE' }
      this.api.getBatch(params).then((res) => {
        const dictTypeName = res.data
        if (dictTypeName) {
          // 该产品不在配置内，直接返回一览
          if (
            dictTypeName.every((item) => item.dictId !== capitalSideProductId)
          ) {
            this.jump()
            this.warning('未配置产品，请联系管理员')
            return
          }
          // 普配页面
          dictTypeName.forEach((item) => {
            if (item.dictId === capitalSideProductId) {
              const obj = this.product.find((elem) => elem.dictTypeName === item.dictTypeName
              )
              console.log(obj, 'obj')
              if (!obj) {
                this.jump()
                this.warning('未配置产品，请联系管理员')
              } else {
                this.dictType = obj.dictType
              }
              // if (item.dictTypeName === '财富资金') {
              //   this.dictType = '3'
              // } else if (item.dictTypeName === '建行云贷') {
              //   this.dictType = '1'
              // } else if (item.dictTypeName === '深国际') {
              //   this.dictType = '2'
              // } else if (
              //   item.dictTypeName === '中小担' ||
              //   item.dictTypeName === '中小企'
              // ) {
              //   this.dictType = '4'
              // } else {
              //   this.jump()
              //   this.warning('未配置产品，请联系管理员')
              // }
            }
            console.log(this.dictType)
          })
        }
      })
    },
    // 页面跳转方法
    jump () {
      this.$router.push({ path: this.tags.path })
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getBatch()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    // this.getBatch()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
</style>
